import { PageProps } from 'gatsby';
import { NoteData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Note';
import List from '~/containers/Espace/List';
import note from '~/params/note.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useAdminNotes from '~/utils/useAdminNotes';

const PageAdminNotes: FC<
  PageProps & UserProps & { pageContext: { tab?: number } }
> = ({ location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();
  const items = useAdminNotes({
    pathname: location.pathname,
  });

  return (
    <TemplateAdmin>
      <List
        itemList={Item}
        model={
          new NoteData({
            params: note,
            wheres: {
              parent: '',
            },
          })
        }
        search={location.search}
        subMenu={items}
        tabIndex={tab ?? 0}
        title={t('notes.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminNotes, 'admin');
